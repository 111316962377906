import $ from 'jquery';
import animation from 'components/controls/animation';
import gallery from 'components/blocks/gallery/gallery';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const targetElement = document.querySelector('nav');

export default function () {
	$(document).on('click', 'a[href^="/"]', function () {
		var link = $(this).attr('href');
		var load = true;

		$('.loading').addClass('is-load');

		setTimeout(function () {
			$('.page__content').load(link + ' .page__content', function (response, status, xhr) {

				var title = $(response).filter('title').text();
				$('title').text(title);

				window.history.pushState('page', title, link);
				enableBodyScroll(targetElement);

				if (load) {
					animation(1);
					gallery();
					$('.loading').removeClass('is-load');
					load = false;
				}
			});

			if ($(this).closest('.nav')) {
				$('.toggle').removeClass('is-open');
				$('nav').removeClass('is-open');
			}
		}, 800);

		return false;
	});

	$('a[href^="#"]').click(function () {

	});

	window.onpopstate = function () {
		var link = document.location.pathname;

		$('.page__content').load(link + ' .page__content, #title', function (response, status, xhr) {
			var title = $(response).filter('title').text();
			$('title').text(title);
		});
	};

	animation();
}
