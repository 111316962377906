import $ from 'jquery';
import Swiper from 'swiper/dist/js/swiper.min.js';

export default function () {
	function gallery() {
		var swiper = new Swiper('.gallery .swiper-container', {
			slidesPerView: 'auto',
			spaceBetween: 30,
			freeMode: true
		});
	}

	gallery();
}
